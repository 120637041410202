import { Alert } from "react-bootstrap";
import SubNavigation from "../components/SubNavigation";
import { useEffect, useState } from "react";
import Constants from "../Constants";
const Home = ({ children }) => {
  const [warning, setWarning] = useState('');

  useEffect(() => {
    (async () => {
      let url = Constants.staticContent + 'banner';
      let response = await fetch(url)
      let payload = await response.json()

      setWarning(payload?.data?.content)

    })();
  }, [])
  return (
    <div className="main-container">
      {warning ?
        (<Alert onClose={() => setWarning('')} variant="info" className="main-warning" dismissible="true" style={{ marginInline: "0.8rem" }}>
         <i className="fa-light fa-exclamation-circle me-2"></i> {warning}
        </Alert>) : <></>}
      {children}
      <SubNavigation />
    </div>
  );
};

export default Home;
