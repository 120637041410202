export default function Favourite({ onClick, isAdded }) {
    return (
        <>
            {
                isAdded ? (
                    <i className="fa-solid fa-star text-warning fs-5 btn-fav cursor-pointer" onClick={onClick}></i>
                ) : (
                    <i className="fa-regular fa-star text-warning fs-5 btn-fav cursor-pointer" onClick={onClick}></i>
                )
            }
        </>
    )
}