import React from "react";
import Constants from "../Constants";
const Privacy = () => {

  const [content, setContent] = React.useState('');
  React.useEffect(() => {
    let url = Constants.staticContent + 'privacy';


    (async () => {
        let response = await fetch(url)
        let data = await response.json();
        if(data.status === 200){
            setContent(data?.data?.content);
        }
    })()
}, [])


  return (
    <div className="container">
    <div className="row">
      <div className="col-12 content-paging">
        <h1>Privacy Policy</h1>
      </div>
      <div className="col-12 content__container" dangerouslySetInnerHTML={{__html: content}} />
    </div>
  </div>
  );
};

export default Privacy;
