import * as L from "leaflet";
import * as M from "leaflet.markercluster";
import React, { useRef } from "react";
import Constants from "../Constants";
import DetailCard from "./DetailCard";
import { useFavourite, useDefaultPan } from "../hooks";
import DefaultButton from "./DefaultButton";
import Spinner from "../Utils/Spinner";


function createClusterClassName(closed, caution) {
  if(closed) {
    return "marker-cluster-large"
  } else if(caution) {
    return "marker-cluster-medium"
  } else {
    return "marker-cluster-small"
  }
}

const Icon = (state) => {
  return L.icon({
    iconUrl: `/static/svg/${state}.png`,
    iconSize: [20, 20],
    iconAnchor: [0, 0],
  });
};
const ClosoureMap = () => {

  
  const [closures, setclosures] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [favs, toggleFav] = useFavourite()
  const [pan, setPan] = useDefaultPan()
  const defaultRef = useRef()

  React.useEffect(() => {

    let url = Constants.closures;
    let position = pan ? Object.values(pan.position) : [30.266666, -97.73333]
    let zoom = pan ? pan.zoom : 10;
    let map = L.map("map").setView(position, zoom, { maxZoom: 18 });

    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setLoading(false);
      let markers = new M.MarkerClusterGroup({
        maxClusterRadius: 10,
        iconCreateFunction: (cluster) => {
          let markers = cluster.getAllChildMarkers();
          let isAnyClosure = markers.some((marker) => marker?.options?.icon?.options?.iconUrl?.includes('closed.png'))
          let isAnyCaution = markers.some((marker) => marker?.options?.icon?.options?.iconUrl?.includes('caution.png'))
          // console.log(isAnyClosure)
          let html = `<div class="leaflet-marker-icon marker-cluster ${createClusterClassName(isAnyClosure, isAnyCaution)} leaflet-zoom-animated leaflet-interactive"><div><span>${markers.length}</span></div></div>`;
          return L.divIcon({ html: html, className: 'mycluster', iconSize: L.point(32, 32) });
        }
      });

      // console.log(data);

      data.attributes.forEach((location, index) => {
        let marker = L.marker([location.lat, location.lon], {
          icon: Icon(location.status),
        }).on("click", () => setSelectedId(index));
        markers.addLayer(marker)
      });
      
      L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
        }).addTo(
          map
        );
      map.addLayer(markers)
      map.on("mousemove", () => {
        let zoom = map.getZoom()
        let position = map.getCenter()

        defaultRef.current = { zoom, position }
      })
      setclosures(data);
    })();
    return () => {
      map.remove()
    }


  }, [pan]);
  const handleSetDefault = (e) => {
    setPan(defaultRef.current)
  }
  return (
    <>
      <div id="map">
        <DefaultButton onClick={handleSetDefault}/>
        {loading ? <Spinner /> : <></>}
      </div>
      <DetailCard
        data={closures}
        id={selectedId}
        setId={setSelectedId}
        header={true}
        toggleFav={toggleFav}
        favs={favs}
        content="CROSSINGS ARE CLOSED"
      />
    </>
  );
};

export default ClosoureMap;
