//Live = 'https://api.atxfloods.com/'
//Local = 'http://192.168.0.110/'
const prefix = "https://api.atxfloods.com/";

const Constants = {
    baseUrl: (path) => {
        return prefix + path
    },
    crossings: prefix+'api/crossings',
    cameras: prefix+'api/cameras',
    closures: prefix+'api/closures',
    contactAdd: prefix + 'api/contacts/create',
    staticContent: prefix + 'admin/static/'
}

export default Constants;