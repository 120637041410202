import * as L from "leaflet";
import * as M from "leaflet.markercluster";
import React, { useRef } from "react";
import Constants from "../Constants";
import DetailCard from "./DetailCard";
import { useFavouriteCamera, useDefaultPan } from "../hooks";
import DefaultButton from "./DefaultButton";
import Spinner from "../Utils/Spinner";

const CamerasMap = () => {

  const Icon = () => {
    return L.icon({
      iconUrl: `/static/svg/camera.png`,
      iconSize: [20, 20],
      iconAnchor: [0, 0],
    });
  };

  const [cameras, setcameras] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [favs, toggleFav] = useFavouriteCamera()
  const [pan, setPan] = useDefaultPan()
  const [load, setLoad] = React.useState(false)
  const [reload, setReload] = React.useState({});
  const defaultRef = useRef()

  React.useEffect(() => {

    const url = Constants.cameras;
    (async () => {
      let response = await fetch(url);
      let data = await response.json();
      setcameras(data);
      setLoad(true)

    })();

    let timeoutId = window.setInterval(() => {
      setReload({})
    }, 20000)

    return () => window.clearInterval(timeoutId)

  }, [pan, reload]);
  React.useEffect(() => {
    let data = cameras
    let position = pan ? Object.values(pan.position) : [30.266666, -97.73333]
    let zoom = pan ? pan.zoom : 10;
    let map = L.map("map").setView(position, zoom, { maxZoom: 18 });
    let markers = new M.MarkerClusterGroup({ maxClusterRadius: 10 });
    if (load) {
      data.attributes.forEach((location, index) => {
        let marker = L.marker([location.lat, location.lon], {
          icon: Icon(),
        }).on("click", () => setSelectedId(index));
        markers.addLayer(marker)
      });
      L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
        }).addTo(
          map
        );
      map.addLayer(markers)
      map.on("mousemove", () => {
        let zoom = map.getZoom()
        let position = map.getCenter()

        defaultRef.current = { zoom, position }
      })
      setLoading(false);
    }

    return () => {
      map.remove()
    }
    // @eslint-disable-next-line
  }, [load]) 
  const handleSetDefault = (e) => {
    setPan(defaultRef.current)
  }


  return (
    <>

      <div id="map">
        <DefaultButton onClick={handleSetDefault} />
        {loading ? <Spinner /> : <></>}
      </div>
      <DetailCard
        data={cameras}
        id={selectedId}
        setId={setSelectedId}
        header={false}
        toggleFav={toggleFav}
        favs={favs}
        camera={true}
      />
    </>
  );
};

export default CamerasMap;
