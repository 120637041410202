import { useEffect, useState } from "react";

export function useFavourite() {
    let [ favs, setFavs ] = useState([])

    useEffect(() => {
        let { localStorage } = window
        let favStr = localStorage.getItem('_favs') || '[]'

        setFavs(JSON.parse(favStr))

    }, [])

    const toggleFavourite = (id) => {
        let { localStorage } = window
        let nFavs = favs.includes(id) ? favs.filter(item => parseInt(item) !== parseInt(id)) : [...favs, parseInt(id)]

        localStorage.setItem('_favs', JSON.stringify(nFavs))
        setFavs(nFavs)
        
    }

    return [favs, toggleFavourite]
}

export function useFavouriteCamera() {
    let [ favs, setFavs ] = useState([])

    useEffect(() => {
        let { localStorage } = window
        let favStr = localStorage.getItem('_favcam') || '[]'

        setFavs(JSON.parse(favStr))

    }, [])

    const toggleFavourite = (id) => {
        let { localStorage } = window
        let nFavs = favs.includes(id) ? favs.filter(item => parseInt(item) !== parseInt(id)) : [...favs, parseInt(id)]

        localStorage.setItem('_favcam', JSON.stringify(nFavs))
        setFavs(nFavs)
        
    }

    return [favs, toggleFavourite]
}

export function useDefaultPan() {
    let [ pan, setPan] = useState(null) 

    useEffect(() => {
        let { localStorage } = window
        let favStr = localStorage.getItem('_pan') || null

        setPan(JSON.parse(favStr))
    }, [])

    const setDefaultPan = (nPan) => {
        let { localStorage } = window
        localStorage.setItem('_pan', JSON.stringify(nPan))

        setPan(nPan)
    }

    return [pan, setDefaultPan]
}