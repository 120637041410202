import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { NavLink } from "react-router-dom";

function SubNavigation() {

  const toggleSideBar = () => {
    let label = document.createElement('label')
    label.setAttribute('for', 'side-toggle')
    document.body.appendChild(label)
    label.click();
    document.body.removeChild(label)
  }
  return (
    <ButtonGroup className='sub-nav' onClick={toggleSideBar}>
      <NavLink className={({ isActive }) => 'btn btn-default ' + (isActive ? 'active' : '')} to='/crossings'>
        <span className='d-none d-lg-block'>
          All Crossings
        </span>
        <i className='fa-regular fa-bicycle d-lg-none'></i>
      </NavLink>
      <NavLink className={({ isActive }) => 'btn btn-default ' + (isActive ? 'active' : '')} to='/cameras' >
      <span className='d-none d-lg-block'>
          Cameras
        </span>
        <i className='fa-regular fa-camera d-lg-none'></i>
      </NavLink>
      <NavLink className={({ isActive }) => 'btn btn-default ' + (isActive ? 'active' : '')} to='/' >
      <span className='d-none d-lg-block'>
          Closures
        </span>
        <i className='fa-regular fa-times-circle d-lg-none'></i>
      </NavLink>
    </ButtonGroup>
  )
}

export default SubNavigation;